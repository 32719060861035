<i18n>
{
  "de": {
    "pageTitle": "Anmelden",
    "login": "Login",
    "emailLabel": "E-Mail-Adresse",
    "passwordLabel": "Passwort",
    "loginErrorMessage": "Sie haben entweder eine unbekannte E-Mail-Adresse oder ein falsches Passwort eingegeben!",
    "emailErrorMessage": "Bitte geben Sie eine gültige E-Mail-Adresse ein!",
    "demoHint": "Falls Sie Ihr Passwort vergessen haben oder noch keinen CO₂mpass-Zugang besitzen, wenden Sie sich bitte an den {0}.",
    "compassSupport": "CO₂mpass Support"
  }
}
</i18n>

<template>
  <MainLayout class="p-login-page">
    <template #default>
      <div class="login">
        <h1>{{ $t('pageTitle') }}</h1>
        <form @submit.prevent="onLoginSubmit">
          <!-- Email -->
          <div class="form-item">
            <label for="email">{{ $t('emailLabel') }}</label>
            <input
              id="email"
              v-model="email"
              type="email"
              inputmode="email"
              autocomplete="username"
              autocapitalize="off"
              autocorrect="off"
              :class="{ 'invalid-email': !isEmailValid && hasBeenBlurred }"
              @blur="onEmailBlur"
            />
            <div v-if="!isEmailValid && hasBeenBlurred" class="error-message">
              <span>
                {{ $t('emailErrorMessage') }}
              </span>
            </div>
          </div>

          <!-- Password -->
          <div class="form-item">
            <label for="password">{{ $t('passwordLabel') }}</label>
            <input id="password" v-model="password" type="password" maxlength="50" autocomplete="current-password" />
          </div>

          <!-- Button -->
          <div class="button-container">
            <button type="submit" :disabled="!isSubmitEnabled" class="button button--primary">
              {{ $t('login') }}
            </button>
          </div>

          <!-- Error -->
          <div v-if="loginFailed" class="error-message">
            {{ $t('loginErrorMessage') }}
          </div>

          <!-- Hint -->
          <i18n path="demoHint" tag="p" class="demo-hint">
            <a href="mailto:support.compass@tend.ch">{{ $t('compassSupport') }}</a>
          </i18n>
        </form>

        <!-- TODO: Password forgotten -->
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isValidEmail } from '@/services/util.js'

import MainLayout from '@/pages/layouts/MainLayout.vue'

import router from '@/router'

export default {
  name: 'login',

  components: {
    MainLayout,
  },

  data() {
    return {
      email: '',
      password: '',
      isEmailValid: false,
      hasBeenBlurred: false,
    }
  },

  computed: {
    ...mapGetters({
      loggedIn: 'account/loggedIn',
      loggingIn: 'account/loggingIn',
      loginFailed: 'account/loginFailed',
    }),

    isSubmitEnabled() {
      return !this.loggingIn && this.isEmailValid && this.password
    },
  },

  watch: {
    loggedIn(to) {
      if (to) {
        router.replace(this.$route.query.redirect ? this.$route.query.redirect : '/')
      }
    },

    email() {
      this.isEmailValid = isValidEmail(this.email)
      if (this.logginFailed) {
        this.reset()
      }
    },
  },

  created() {
    // reset login status
    this.logout()
  },

  methods: {
    ...mapActions({
      login: 'account/login',
      logout: 'account/logout',
      reset: 'account/reset',
    }),

    // Only add errors once user has blurred the input
    onEmailBlur() {
      this.hasBeenBlurred = true
    },

    // Used on form element, need rescope `this`
    onLoginSubmit() {
      const { email, password } = this
      const trimmedEmail = email.trim()
      if (trimmedEmail && password) {
        this.login({ email: trimmedEmail, password })
      }
    },
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style lang="scss">
.p-login-page {
  & .c-layout-header {
    & .primary-nav {
      border: none !important;
    }
  }
}
</style>

<style lang="scss" scoped>
.p-login-page {
  & .login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    min-height: calc(65svh);
    margin: 0 auto;
  }

  & form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xxs);
    margin: var(--spacing-m) auto;

    & .form-item {
      background-color: #fff;
      margin-bottom: 0px;
      transition: margin 0.2s ease-in;

      & label {
        display: block;
        font-weight: 500;
        line-height: 28px;
      }

      &:has(.error-message) {
        margin-bottom: 20px;

        & .error-message span {
          opacity: 1;
        }
      }
    }
  }

  & .button-container {
    margin: var(--spacing-s) 0 0;

    & .button {
      width: 100%;
    }
  }

  & .demo-hint {
    margin: var(--spacing-xl) 0 0;

    & a {
      text-decoration: underline;
    }
  }

  & .error-message {
    position: relative;
    color: var(--error-color);
    font-size: 15px;
    line-height: 20px;

    & span {
      position: absolute;
      transition: opacity 0.3s ease-in;
      opacity: 0;
    }
  }

  & input {
    &.login-error,
    &.invalid-email {
      border-color: var(--error-color) !important;

      &:focus {
        border-color: var(--error-color) !important;
        outline: 3px solid #fdd !important;
      }
    }
  }
}
</style>
